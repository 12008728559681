const billDetails = {
  state: {
    pageType: '',
    moduleType: '',
    beginUpdate: 1,
    reloadList: 1, // 重新加载列表
    transactionReconciliationQuery: {
      executeStatus: -1,
      queryString: '',
      startDate: '',
      endDate: '',
      companyId: 0
    },

    detailBean: 0, // 对账详情实体
    maintenanceReconciliationQuery: {}
  },
  mutations: {
    set_billDetails_pageType (state, data) {
      state.pageType = data
    },
    set_billDetails_moduleType (state, data) {
      state.moduleType = data
    },
    set_billDetails_update (state, data) {
      state.beginUpdate = data
    },
    set_billDetails_reloadList (state, data) {
      state.reloadList = data
    },
    set_billDetails_transactionReconciliationQuery (state, data) {
      state.transactionReconciliationQuery = data
    },
    set_billDetails_detailBean (state, data) {
      state.detailBean = data
    },
    set_billDetails_maintenanceReconciliationQuery (state, data) {
      state.maintenanceReconciliationQuery = data
    }
  },
  getters: {

  },
  actions: {

  }
}

export default billDetails
